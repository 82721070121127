/* https://webaim.org/techniques/css/invisiblecontent/ */

.screen-reader-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
