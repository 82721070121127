@import '../../styles/colors.css';
@import '../../styles/depth.css';
@import '../../styles/metrics.css';
@import '../../styles/typography.css';

.file-input > label {
	align-items: center;
	display: inline-flex;
	position: relative;
}

.file-input-control {
	position: relative;
}

.file-input.orientation-vertical > label {
	align-items: flex-start;
	flex-direction: column;
}

.file-input.orientation-vertical > label input {
	margin-left: 0;
	margin-top: var(--grid-size);
}
