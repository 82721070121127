@import '../../../styles/colors.css';

#link-arrowhead path {
	fill: var(--gray);
	stroke: none;
}

#link-broken circle {
	fill: var(--dark-red);
}

#link-broken path {
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 2px;
	stroke: var(--white);
}

#link-start circle {
	fill: var(--dark-green);
}

#link-start circle.fill-white {
	fill: var(--white);
}

#link-start path {
	fill: none;
	stroke: var(--white);
	stroke-linecap: round;
	stroke-linejoin: round;
}
