@import '../../styles/animations.css';

.menu-button-menu .icon-button {
	/* balancing the icon on the left */
	padding-right: 32px;
}

.menu-button-menu {
	z-index: 2000;
}
