@import './colors.css';

:root {
	/* Used for cards. */
	--shadow-small: 0 1px 1px var(--light-gray);

	/* Used for dropdown menus and other things that hover over the UI. */
	--shadow-large: 0 0 1px var(--gray), 0 2px 8px var(--light-gray);
}

[data-app-theme='dark'] {
	--shadow-small: 0 0 1px var(--gray);
	--shadow-large: 0 0 1px var(--dark-gray), 0 0 10px 2px var(--white);
}
