@import '../../styles/colors.css';
@import '../../styles/metrics.css';

.meter .meter-bar {
	background: transparent;
	border: 1px solid var(--light-gray);
	border-radius: var(--corner-round);
	height: calc(var(--corner-round) * 2);
	position: relative;
}

.meter .meter-bar .filled {
	background-color: var(--blue);
	border-radius: var(--corner-round);
	display: block;
	height: 100%;
	transition: 0.5s width;
}

.meter .meter-label {
	margin-top: calc(var(--grid-size) / 2);
	text-align: center;
}
