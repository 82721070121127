@import '../../../styles/animations.css';
@import '../../../styles/typography.css';
@import '../../../styles/metrics.css';

.dialog-card {
	height: 100%;
	width: 100%;
}

.dialog-card.highlighted {
	animation: 0.4s wiggle linear;
}

.dialog-card .card {
	padding: 0;
}

.dialog-card h2 {
	align-items: center;
	display: flex;
}

.dialog-card h2 .dialog-card-header {
	flex-shrink: 1;
	flex-grow: 1;
	min-width: 0;
}

.dialog-card h2 .dialog-card-header-controls {
	flex-shrink: 1;
}

.dialog-card h2 .dialog-card-header .icon-button {
	display: flex;
	font-size: 1rem;
	font-weight: bold;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	width: 100%;
}

.dialog-card.background h2 .dialog-card-header .icon-button {
	font-size: 0.9rem;
	font-weight: normal;
}

.dialog-card.background h2 .dialog-card-header .icon-button svg {
	display: none;
}

.dialog-card .card-body {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.dialog-card .card-body h2 {
	font: bold 100% var(--font-system);
}

.dialog-card .card-content {
	min-height: 0;
	overflow: auto;
}