.icon-loading svg {
	stroke-dasharray: 32;
	animation: 1.5s loading-spinner-spin linear infinite;
}

@keyframes loading-spinner-spin {
	from {
		stroke-dashoffset: 64;
	}

	to {
		stroke-dashoffset: 0;
	}
}
