@import '../../styles/colors.css';
@import '../../styles/metrics.css';

.tag-button .menu-button > .icon-button {
	background-color: var(--off-white);
	border: 1px solid var(--light-gray);
	border-radius: var(--control-height);
	height: var(--control-height);
	font-size: 85%;
}

.tag-button .menu-button > .icon-button:focus {
	box-shadow: inset 0 0 0px 2px var(--blue), 0 1px 1px var(--light-gray);
}

.tag-button .menu-button > .icon-button:hover {
	background-color: var(--light-gray);
}

.tag-button.color-red .menu-button > .icon-button {
	background-color: var(--faint-red);
	border-color: var(--light-red);
}

.tag-button.color-red .menu-button > .icon-button .icon {
	color: var(--dark-red);
}

.tag-button.color-red .menu-button > .icon-button:hover {
	background-color: var(--light-red);
}

.tag-button.color-orange .menu-button > .icon-button {
	background-color: var(--faint-orange);
	border-color: var(--light-orange);
}

.tag-button.color-orange .menu-button > .icon-button .icon {
	color: var(--dark-orange);
}

.tag-button.color-orange .menu-button > .icon-button:hover {
	background-color: var(--light-orange);
}

.tag-button.color-yellow .menu-button > .icon-button {
	background-color: var(--faint-yellow);
	border-color: var(--light-yellow);
}

.tag-button.color-yellow .menu-button > .icon-button .icon {
	color: var(--dark-yellow);
}

.tag-button.color-yellow .menu-button > .icon-button:hover {
	background-color: var(--light-yellow);
}

.tag-button.color-green .menu-button > .icon-button {
	background-color: var(--faint-green);
	border-color: var(--light-green);
}

.tag-button.color-green .menu-button > .icon-button .icon {
	color: var(--dark-green);
}

.tag-button.color-green .menu-button > .icon-button:hover {
	background-color: var(--light-green);
}

.tag-button.color-blue .menu-button > .icon-button {
	background-color: var(--faint-blue);
	border-color: var(--light-blue);
}

.tag-button.color-blue .menu-button > .icon-button .icon {
	color: var(--dark-blue);
}

.tag-button.color-blue .menu-button > .icon-button:hover {
	background-color: var(--light-blue);
}

.tag-button.color-purple .menu-button > .icon-button {
	background-color: var(--faint-purple);
	border-color: var(--light-purple);
}

.tag-button.color-purple .menu-button > .icon-button .icon {
	color: var(--dark-purple);
}

.tag-button.color-purple .menu-button > .icon-button:hover {
	background-color: var(--light-purple);
}
