@import '../../../styles/colors.css';
@import '../../../styles/metrics.css';

.button-bar {
	display: flex;
	flex-wrap: wrap;
}

.button-bar.orientation-vertical {
	flex-direction: column;
}
