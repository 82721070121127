.fade-in-out-enter-active {
	animation: fade-in 0.2s;
}

.fade-in-out-exit-active, .fade-out-exit-active {
	animation: reverse fade-in 0.2s;
}

.pop-enter-active {
	animation: pop-in 0.2s;
}

.pop-exit-active {
	animation: reverse pop-in 0.2s forwards;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes pop-in {
	from {
		opacity: 0;
		transform: scale(0.5);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes mini-pop-in {
	from {
		transform: scale(1.05);
	}

	to {
		transform: scale(1);
	}
}

@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(-1deg);
	}

	50% {
		transform: scale(1.025) rotate(1deg);
	}

	75% {
		transform: rotate(-1deg);
	}

	100% {
		transform: scale(1) rotate(0deg);
	}
}