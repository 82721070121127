@import '../../styles/colors.css';
@import '../../styles/metrics.css';

.main-content {
	background: var(--off-white);
	min-height: calc(100vh - 2 * var(--control-height));
	overflow: auto;
	position: fixed;
	top: calc(2 * var(--control-height));
	/* height of <top-bar> */
	left: 0;
	right: 0;
	bottom: 0;
}

.main-content.padded {
	padding: var(--ui-gap);
}

.main-content-grab-listener {
	cursor: grab;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
}

.main-content-grab-listener.grabbing {
	cursor: grabbing;
}
