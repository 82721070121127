.story-edit-route .marquee-selection {
	/* Above .passage-map. */
	z-index: 3;
}

.story-edit-route .zoom-buttons {
	z-index: 4;
}

.story-edit-route .passage-map {
	position: absolute;
	top: 0;
	left: 0;
}

.story-edit-route .link-connectors {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

body.dragging-passages .drop {
	display: none !important;
}

body.dragging-passages .story-edit-route * {
	cursor: default !important;
}
