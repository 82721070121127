@import '../../styles/colors.css';
@import '../../styles/metrics.css';

.passage-card {
	cursor: default;
	font-size: 85%;
	position: absolute;
	user-select: none;
}

.passage-card .selectable-card {
	height: 100%;
}

.passage-card .card {
	padding: calc(var(--grid-size) / 2);
}

.passage-card .card-content {
	padding: 0;
}

.passage-card .card-content .placeholder {
	font-style: italic;
}

.passage-card h2 {
	color: var(--black);
	font-size: 100%;
	font-weight: normal;
}

.passage-card .card-content {
	color: var(--gray);
	padding: 0;
	overflow: ellipsis;
}

.passage-card.empty .card {
	background: hsla(0, 100%, 100%, 0.33);
	border: 1px dashed var(--gray);
	box-shadow: none;
	backdrop-filter: blur(1px);
}

.passage-card.empty.selected .card {
	background: hsla(212, 90%, 60%, 0.1);
	border: 1px dashed var(--blue);
	box-shadow: none;
}

.compact-passage-cards .passage-card h2 {
	align-items: center;
	display: flex;
	flex-grow: 1;
	font-size: 160%;
	justify-content: center;
	text-align: center;
	word-break: break-word;
}

.compact-passage-cards .passage-card .card-content {
	display: none;
}

.compact-passage-cards .passage-card .tag-stripe span {
	border-radius: 4px;
	height: 8px;
}

[data-app-theme='dark'] .passage-card.empty .card {
	background: hsla(0, 100%, 100%, 0.1);
}

[data-app-theme='dark'] .passage-card.empty.selected .card {
	background: hsla(212, 90%, 60%, 0.2);
}
